export function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function getTimezoneOffset() {
  const offsetInMinutes = new Date().getTimezoneOffset()
  const offsetInHours = Math.floor(Math.abs(offsetInMinutes) / 60)
  const offsetMinutes = Math.abs(offsetInMinutes) - offsetInHours * 60

  const sign = offsetInMinutes > 0 ? '-' : (offsetInMinutes < 0 ? '+' : '±')
  return `${sign}${getNumberWithZero(offsetInHours)}:${getNumberWithZero(offsetMinutes)}`
}

const getNumberWithZero = (number) => {
  return number < 10 ? '0' + number : number
}
