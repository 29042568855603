import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { sendBulkMessage } from '@/application/services/message.js'
import { useSubmitMessage } from '@/application/composables/submitMessage.js'
import { getTimezone, getTimezoneOffset } from '@/application/utils/date.js'
import { timezones } from '@/application/utils/timezone.js'
import { time } from '@/application/utils/time.js'
import { weekDays } from '@/application/utils/weekdays.js'
import { canSendBulk } from '@/application/utils/license'
import { store } from '@/application/store'

export function useBulkMessageCreate(license, limit) {
  const i18n = useI18n()
  const successLangPhrase = 'bulkMessagesSuccessResponse'

  const currentTimezone = getTimezone()
  const addCurrentTimezone = () => {
    if (currentTimezone.length) {
      const isAlreadyExist = timezones.some(item => item.value === currentTimezone)
      if (!isAlreadyExist) {
        timezones.unshift({
          value: currentTimezone,
          title: `(UTC${getTimezoneOffset()}) ${currentTimezone.split('/')[1]}`,
        })
      }
    }
  }
  const getCurrentTimezoneValue = () => {
    addCurrentTimezone()
    // console.log(timezones)
    const currentValue = timezones.find(item => {
      return item.value === currentTimezone
    })
    if (currentValue) {
      return currentValue.value
    }
  }
  const senderNames = license.sender_names
  const rememberedData = store.getters.getBulkFormCache(license)

  const cleanFieldValue = {
    bulk_agreement: false,
    bulk_schedule: false,
    bulk_sandbox: license.sandbox_only,
    bulk_time_zone: getCurrentTimezoneValue() || '',
    bulk_sending_days: weekDays,
    bulk_sender_name: senderNames && senderNames.length === 1 ? senderNames[0] : null,
    bulk_from_time: [...time].shift(),
    bulk_to_time: [...time].pop(),
    bulk_text: '',
    bulk_recipients: '',
    bulk_from_date: '',
  }
  // initial values of form
  const formdata = {
    ...cleanFieldValue,
    ...rememberedData,
  }
  const { submit, isDisabled } = useSubmitMessage(license, sendBulkMessage, successLangPhrase, true, { ...cleanFieldValue }, limit)
  const submitButton = computed(() => ({
    wrapper: 'justify-content-start',
    class: 'btn btn-primary shadow-2 mt-4',
    text: i18n.t('send'),
  }))

  const resetButton = computed(() => ({
    wrapper: 'd-flex flex-row justify-content-end',
    class: 'btn btn-outline-danger shadow-2 mt-4',
    text: i18n.t('reset'),
    clearStorage: () => {
      store.dispatch('clearFormData', { license, type: 'BULK' })
    },
  }))

  return {
    license,
    submitButton,
    resetButton,
    submit,
    formdata,
    cleanFieldValue,
    senderNames,
    isDisabled: isDisabled,
    addCurrentTimezone,
    bulkAllowed: canSendBulk(license),
    needPrevalidation: Object.values(formdata).join('') !== Object.values(cleanFieldValue).join(''),
    onChangeHandler: (data) => {
      store.dispatch('saveBulkFormData', { license, data })
    },
    onChangeDateHandler: (date) => {
      const currentData = store.getters.getBulkFormCache(license)
      store.dispatch('saveBulkFormData', { license, data: { ...currentData, bulk_from_date: date } })
    },
  }
}
