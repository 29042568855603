<template>
  <Form
    :initial-values="formdata"
    @submit="submit"
    @invalid-submit="onInvalidSubmit"
    v-slot="{ values, resetForm }"
    ref="bulkForm"
  >
    <div class="form-group">
      <label for="bulk_sender_name">{{ $t('senderName') }}</label>
      <Field
        as="select"
        name="bulk_sender_name"
        id="bulk_sender_name"
        class="form-control mb-0 form-group"
        @change="() => { fieldChanged(values) }"
      >
        <option
          :key="item"
          :value="item"
          v-for="item in senderNames"
        >{{ item }}</option>
      </Field>
      <ErrorMessage
        as="div"
        name="bulk_sender_name"
        class="error-message"
      />
    </div>

    <div class="form-group">
      <label for="bulk_text">{{ $t('text') }}</label>
      <Field
        as="textarea"
        name="bulk_text"
        id="bulk_text"
        rules="required"
        :validate-On-Mount="needPrevalidation"
        class="form-control mb-0"
        v-model="formdata.bulk_text"
        @change="() => { fieldChanged(values) }"
      ></Field>
      <ErrorMessage
        as="div"
        name="bulk_text"
        class="error-message"
      />
    </div>

    <div class="form-group">
      <label for="bulk_recipients">{{ $tc('phoneOrEmail', 2) }}</label>
      <Field
        as="textarea"
        name="bulk_recipients"
        id="bulk_recipients"
        rules="required|phone_email_list"
        :validate-On-Mount="needPrevalidation"
        :validateOnInput="false"
        class="form-control mb-0 form-group"
        v-model="formdata.bulk_recipients"
        placeholder="+13231112233, 1(787)1112233"
        @blur="recipientsBlur"
        @change="() => { fieldChanged(values) }"
      ></Field>
      <small
        class="form-text text-muted"
      >{{ $t('recipientsContact') }}</small>
      <small
        class="form-text text-muted"
      >{{ $t('recipientsContactQuantity') }}: {{recipientsCount}} / {{ bulkMessagesCountLimit }}</small>
      <small
        v-if="duration.quantity"
        class="form-text text-muted"
      >{{ $t('estimatedDuration') }}:
        <template v-if="duration.quantity === 1 && duration.unit === 'min'">{{$t('estimatedDurationMin')}}</template>
        <template v-else>~{{duration.quantity}} {{$tc('units.' + duration.unit, duration.quantity)}}</template>
      </small>
      <ErrorMessage
        as="div"
        name="bulk_recipients"
        class="error-message"
        v-slot="{message}"
      >{{message ? message + ' ' + inValidRecipients : ''}}</ErrorMessage>
    </div>

    <div class="form-group">
      <label for="bulk_media_url">{{ $t('mediaURL') }}</label>
      <Field
        as="input"
        type="text"
        name="bulk_media_url"
        id="bulk_media_url"
        rules="media_url"
        v-model="formdata.bulk_media_url"
        :validate-On-Mount="needPrevalidation"
        class="form-control mb-0 form-group"
        @change="() => { fieldChanged(values) }"
      ></Field>
      <ErrorMessage
        as="div"
        name="bulk_media_url"
        class="error-message"
      />
    </div>

    <div class="checkbox checkbox-primary d-inline">
      <Field
        as="input"
        type="checkbox"
        name="bulk_agreement"
        id="bulk_agreement"
        rules="required"
        v-model="formdata.bulk_agreement"
        :value="true"
        :unchecked-value="false"
        :validate-On-Mount="true"
        @change="() => { fieldChanged(values) }"
      ></Field>
      <label for="bulk_agreement" class="cr" v-html="$t('termsRulesAgreement')"></label>
      <ErrorMessage
        as="div"
        name="bulk_agreement"
        class="error-message"
      />
    </div>

    <div class="checkbox checkbox-primary d-inline">
      <Field
        as="input"
        type="checkbox"
        name="bulk_sandbox"
        id="bulk_sandbox"
        v-model="formdata.bulk_sandbox"
        :value="true"
        :unchecked-value="false"
        @change="() => { fieldChanged(values) }"
      ></Field>
      <label for="bulk_sandbox" class="cr">{{ $t('sendSandboxMessages') }}</label>
      <ErrorMessage
        as="div"
        name="bulk_sandbox"
        class="error-message"
      />
    </div>

    <div class="checkbox checkbox-primary d-inline">
      <Field
        as="input"
        type="checkbox"
        name="bulk_schedule"
        id="bulk_schedule"
        v-model="formdata.bulk_schedule"
        :value="true"
        :unchecked-value="false"
        @change="() => { fieldChanged(values) }"
      ></Field>
      <label for="bulk_schedule" class="cr">{{ $t('scheduleCampaign') }}</label>
      <ErrorMessage
        as="div"
        name="bulk_schedule"
        class="error-message"
      />
    </div>

    <!-- if schedule -->
    <div v-if="formdata.bulk_schedule === true">
      <div class="form-group">
        <label for="bulk_from_date">{{ $t('startDate') }}</label>
        <Field
          as="app-date-picker"
          name="bulk_from_date"
          id="bulk_from_date"
          rules="required: formdata.bulk_schedule === true"
          :validate-On-Mount="needPrevalidation"
          :changeHandler="dateHandler"
          :min-date="new Date()"
          v-model="formdata.bulk_from_date"
        ></Field>
        <ErrorMessage
          as="div"
          name="bulk_from_date"
          class="error-message"
        />
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group">
            <label for="bulk_from_time">{{ $t('fromTime') }}</label>
            <Field
              as="select"
              name="bulk_from_time"
              id="bulk_from_time"
              rules="required: formdata.bulk_schedule === true|time_to_after_from:@bulk_from_time,@bulk_to_time"
              :validate-On-Mount="needPrevalidation"
              class="form-control mb-0 form-group"
              v-model="formdata.bulk_from_time"
              @change="() => { fieldChanged(values) }"
            >
              <option
                v-for="item in timeArrayFrom"
                :key="item"
                :value="item"
              >{{ item }}</option>
            </Field>
          </div>
        </div>

        <div class="col-6">
          <div class="form-group">
            <label for="bulk_to_time">{{ $t('toTime') }}</label>
            <Field
              as="select"
              name="bulk_to_time"
              id="bulk_to_time"
              rules="required: formdata.bulk_schedule === true|time_to_after_from:@bulk_from_time,@bulk_to_time"
              :validate-On-Mount="needPrevalidation"
              class="form-control mb-0 form-group"
              v-model="formdata.bulk_to_time"
              @change="() => { fieldChanged(values) }"
            >
              <option
                v-for="item in timeArray"
                :key="item"
                :value="item"
              >{{ item }}</option>
            </Field>
            <ErrorMessage
              as="div"
              name="bulk_to_time"
              class="error-message"
            />
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="bulk_time_zone">{{ $t('basedOnThisTimezone') }}</label>
        <Field
          as="select"
          name="bulk_time_zone"
          id="bulk_time_zone"
          rules="required: formdata.bulk_schedule === true"
          :validate-On-Mount="needPrevalidation"
          class="form-control mb-0 form-group"
          @change="() => { fieldChanged(values) }"
        >
          <option
            v-for="item in timezonesArray"
            :key="item.value"
            :value="item.value"
          >{{ item.title }}</option>
        </Field>
        <ErrorMessage
          as="div"
          name="bulk_time_zone"
          class="error-message"
        />
        <small class="form-text text-muted">{{ $t('timezone') }}: {{ currentTimezone }}</small>
      </div>

      <p class="mb-0">{{ $t('sendTheseDays') }}</p>
      <ErrorMessage
        as="div"
        name="bulk_sending_days"
        class="error-message"
      />
      <div class="row">
        <div class="col-auto checkbox checkbox-primary d-inline" v-for="day in weekDays" :key="day">
          <Field
            as="input"
            type="checkbox"
            name="bulk_sending_days"
            rules="required: formdata.bulk_schedule === true"
            :validate-On-Mount="needPrevalidation"
            :id="day"
            :value="day"
            :unchecked-value="false"
            @change="() => { fieldChanged(values) }"
          ></Field>
          <label :for="day" class="cr">{{ $t(`weekdays.${day}`) }}</label>
        </div>
      </div>
    </div>
    <div :class="['row', submitButton.wrapper]">
      <div class="col col-6">
        <button
          :disabled="isDisabled === true"
          :class="submitButton.class"
        ><div class="spinner-border spinner-border-sm mr-1" v-if="isDisabled === true"></div>{{ submitButton.text }}</button>
        <small class="form-text text-muted" v-if="license && license.bulk_send_status === 'PREMODERATION'">
          {{ $t('bulkMessagesPremoderationNotification') }}.
        </small>
      </div>
      <div class="col col-6">
        <div :class="resetButton.wrapper">
          <modal-wrapper>
            <template v-slot="{ close }">
              <confirmation-modal
                :close="close"
                :content="$t('confirmationResetForm')"
                @confirm="() => {clearForm(resetForm)}"
              ></confirmation-modal>
            </template>
            <template v-slot:trigger="{ open }">
              <button
                @click.prevent="open"
                :class="resetButton.class"
                :disabled="resetDisabled"
              >{{ resetButton.text }}</button>
            </template>
          </modal-wrapper>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from 'vee-validate'
import { useBulkMessageCreate } from '@/application/composables/bulkMessageCreate.js'
import { useExtractRecipients } from '@/application/composables/extractRecipients.js'
import { time } from '@/application/utils/time.js'
import { weekDays } from '@/application/utils/weekdays.js'
import { timezones } from '@/application/utils/timezone.js'
import { getTimezone } from '@/application/utils/date.js'
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue'
import { store } from '@/application/store'
import { useI18n } from 'vue-i18n'
import { useModal } from '@/application/composables/modal'
import { getDuration } from '@/application/services/message'
import { bulkMessagesCountLimit } from '@/application/utils/limits.js'

export default {
  name: 'BulkMessageForm',
  components: {
    Form,
    Field,
    ErrorMessage,
    'modal-wrapper': defineAsyncComponent(() => import('@/application/components/modals/ModalWrapper.vue')),
    'confirmation-modal': defineAsyncComponent(() => import('@/application/components/modals/ConfirmationModal.vue')),
  },
  props: {
    license: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { extractValidRecipients, extractInValidRecipients } = useExtractRecipients(bulkMessagesCountLimit)
    const {
      license,
      submit,
      senderNames,
      formdata,
      submitButton,
      resetButton,
      isDisabled,
      bulkAllowed,
      cleanFieldValue,
      onChangeHandler,
      onChangeDateHandler,
      needPrevalidation,
    } = useBulkMessageCreate(props.license, bulkMessagesCountLimit)
    const { openModal } = useModal()
    const idOfEstimationRequest = ref(null)
    const setEstimationTime = (count) => {
      const currentId = Date.now().toString(36) + Math.random().toString(36).substr(2)
      idOfEstimationRequest.value = currentId
      if (count > 0) {
        getDuration(count)
          .then(({ data }) => {
            if (currentId === idOfEstimationRequest.value) {
              duration.value.quantity = data.value
              duration.value.unit = data.measurement
            }
          })
      } else {
        duration.value.quantity = 0
        duration.value.unit = null
      }
    }

    const timeArray = time
    const timezonesArray = timezones
    const currentTimezone = getTimezone()
    const formdataRef = ref(formdata)
    const duration = ref({})
    const recipientsCount = computed(() => extractValidRecipients(formdataRef.value.bulk_recipients).length)
    watch(recipientsCount, setEstimationTime)
    const inValidRecipients = ref(extractInValidRecipients(formdataRef.value.bulk_recipients).join(', '))
    const resetDisabled = computed(() => {
      const data = store.getters.getBulkFormCache(license)
      return !data || Object.values(data).filter(item => !!item).length === 0
    })
    const fieldChanged = (values) => { onChangeHandler(values) }
    const dateChanged = (date) => { onChangeDateHandler(date) }
    const i18n = useI18n()

    onMounted(() => {
      if (recipientsCount.value > 0) {
        setEstimationTime(recipientsCount.value)
      }
    })

    return {
      submit,
      submitButton,
      resetButton,
      senderNames,
      formdata: formdataRef,
      timeArrayFrom: timeArray.slice(0, timeArray.length - 2),
      timeArray,
      timezonesArray,
      currentTimezone,
      weekDays,
      isDisabled,
      recipientsCount,
      inValidRecipients,
      bulkAllowed,
      fieldChanged,
      cleanFieldValue,
      needPrevalidation,
      dateChanged,
      resetDisabled,
      duration,
      onInvalidSubmit({ errors }) {
        openModal(i18n.t('error'), [...new Set(Object.values(errors))].join('<br>'))
      },
      recipientsBlur() {
        inValidRecipients.value = extractInValidRecipients(formdataRef.value.bulk_recipients).join(', ')
      },
      bulkMessagesCountLimit,
    }
  },
  methods: {
    clearForm(resetForm) {
      this.resetButton.clearStorage()
      resetForm()
      this.$refs.bulkForm.setValues(this.cleanFieldValue)
      for (const key in this.formdata) {
        if (this.cleanFieldValue[key] !== undefined) {
          this.formdata[key] = this.cleanFieldValue[key]
        }
      }
    },
    dateHandler(newBulkDate) {
      this.dateChanged(newBulkDate)
    },
  },
}
</script>
