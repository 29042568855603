export const timezones = [
  {
    value: 'Etc/GMT+12',
    title: '(UTC-12:00) International Date Line West',
  },
  {
    value: 'Etc/GMT+11',
    title: '(UTC-11:00) Coordinated Universal Time-11',
  },
  {
    value: 'US/Hawaii',
    title: '(UTC-10:00) Hawaii',
  },
  {
    value: 'US/Alaska',
    title: '(UTC-09:00) Alaska',
  },
  {
    value: 'PST',
    title: '(UTC-08:00) Pacific Time (US & Canada)',
  },
  {
    value: 'America/Creston',
    title: '(UTC-07:00) Arizona',
  },
  {
    value: 'America/Chihuahua',
    title: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
  },
  {
    value: 'MST',
    title: '(UTC-07:00) Mountain Time (US & Canada)',
  },
  {
    value: 'America/Belize',
    title: '(UTC-06:00) Belize',
  },
  {
    value: 'CST',
    title: '(UTC-06:00) Central Time (US & Canada)',
  },
  {
    value: 'America/Mexico_City',
    title: '(UTC-06:00) Guadalajara, Mexico City, Monterrey',
  },
  {
    value: 'America/Regina',
    title: '(UTC-06:00) Saskatchewan',
  },
  {
    value: 'America/Bogota',
    title: '(UTC-05:00) Bogota, Lima, Quito',
  },
  {
    value: 'America/Toronto',
    title: '(UTC-05:00) Toronto',
  },
  {
    value: 'EST',
    title: '(UTC-05:00) Eastern Time (US & Canada)',
  },
  {
    value: 'America/Caracas',
    title: '(UTC-04:30) Caracas',
  },
  {
    value: 'America/Asuncion',
    title: '(UTC-04:00) Asuncion',
  },
  {
    value: 'America/Glace_Bay',
    title: '(UTC-04:00) Atlantic Time (Canada)',
  },
  {
    value: 'America/Cuiaba',
    title: '(UTC-04:00) Cuiaba',
  },
  {
    value: 'America/Anguilla',
    title: '(UTC-04:00) Anguilla, Georgetown, La Paz, San Juan',
  },
  {
    value: 'America/Santiago',
    title: '(UTC-04:00) Santiago',
  },
  {
    value: 'America/St_Johns',
    title: '(UTC-03:30) Newfoundland',
  },
  {
    value: 'America/Argentina/Buenos_Aires',
    title: '(UTC-03:00) Buenos Aires',
  },
  {
    value: 'America/Fortaleza',
    title: '(UTC-03:00) Fortaleza, Cayenne',
  },
  {
    value: 'America/Godthab',
    title: '(UTC-03:00) Greenland',
  },
  {
    value: 'America/Montevideo',
    title: '(UTC-03:00) Montevideo',
  },
  {
    value: 'America/Sao_Paulo',
    title: '(UTC-03:00) São Paulo, Rio de Janeiro, Brasília',
  },
  {
    value: 'America/Noronha',
    title: '(UTC-02:00) Fernando de Noronha Time',
  },
  {
    value: 'Atlantic/Azores',
    title: '(UTC-01:00) Azores',
  },
  {
    value: 'Atlantic/Cape_Verde',
    title: '(UTC-01:00) Cape Verde Is.',
  },
  {
    value: 'Africa/Casablanca',
    title: '(UTC) Casablanca',
  },
  {
    value: 'Etc/UTC',
    title: '(UTC) Coordinated Universal Time',
  },
  {
    value: 'Europe/London',
    title: '(UTC+00:00) London, Edinburgh',
  },
  {
    value: 'Europe/Dublin',
    title: '(UTC+00:00) Dublin, Lisbon',
  },
  {
    value: 'Atlantic/Reykjavik',
    title: '(UTC+00:00) Reykjavik, Monrovia',
  },
  {
    value: 'Europe/Amsterdam',
    title: '(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna',
  },
  {
    value: 'Europe/Belgrade',
    title: '(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague',
  },
  {
    value: 'Europe/Brussels',
    title: '(UTC+01:00) Brussels, Copenhagen, Madrid, Paris',
  },
  {
    value: 'Europe/Sarajevo',
    title: '(UTC+01:00) Sarajevo, Skopje, Warsaw, Zagreb',
  },
  {
    value: 'Africa/Algiers',
    title: '(UTC+01:00) West Central Africa',
  },
  {
    value: 'Africa/Windhoek',
    title: '(UTC+01:00) Windhoek',
  },
  {
    value: 'Europe/Athens',
    title: '(UTC+02:00) Athens, Bucharest',
  },
  {
    value: 'Asia/Beirut',
    title: '(UTC+02:00) Beirut',
  },
  {
    value: 'Africa/Cairo',
    title: '(UTC+02:00) Cairo',
  },
  {
    value: 'Asia/Damascus',
    title: '(UTC+02:00) Damascus',
  },
  {
    value: 'EET',
    title: '(UTC+02:00) E. Europe',
  },
  {
    value: 'Africa/Harare',
    title: '(UTC+02:00) Harare, Pretoria',
  },
  {
    value: 'Europe/Helsinki',
    title: '(UTC+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius',
  },
  {
    value: 'Europe/Istanbul',
    title: '(UTC+03:00) Istanbul',
  },
  {
    value: 'Asia/Jerusalem',
    title: '(UTC+02:00) Jerusalem',
  },
  {
    value: 'Africa/Tripoli',
    title: '(UTC+02:00) Tripoli',
  },
  {
    value: 'Asia/Amman',
    title: '(UTC+03:00) Amman',
  },
  {
    value: 'Asia/Baghdad',
    title: '(UTC+03:00) Baghdad',
  },
  {
    value: 'Europe/Kaliningrad',
    title: '(UTC+02:00) Kaliningrad',
  },
  {
    value: 'Asia/Kuwait',
    title: '(UTC+03:00) Kuwait, Riyadh',
  },
  {
    value: 'Africa/Nairobi',
    title: '(UTC+03:00) Nairobi',
  },
  {
    value: 'Europe/Moscow',
    title: '(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk',
  },
  {
    value: 'Europe/Samara',
    title: '(UTC+04:00) Samara, Ulyanovsk, Saratov',
  },
  {
    value: 'Asia/Tehran',
    title: '(UTC+03:30) Tehran',
  },
  {
    value: 'Asia/Dubai',
    title: '(UTC+04:00) Abu Dhabi, Muscat',
  },
  {
    value: 'Asia/Baku',
    title: '(UTC+04:00) Baku',
  },
  {
    value: 'Indian/Mauritius',
    title: '(UTC+04:00) Port Louis',
  },
  {
    value: 'Asia/Tbilisi',
    title: '(UTC+04:00) Tbilisi',
  },
  {
    value: 'Asia/Yerevan',
    title: '(UTC+04:00) Yerevan',
  },
  {
    value: 'Asia/Kabul',
    title: '(UTC+04:30) Kabul',
  },
  {
    value: 'Asia/Tashkent',
    title: '(UTC+05:00) Ashgabat, Tashkent',
  },
  {
    value: 'Asia/Yekaterinburg',
    title: '(UTC+05:00) Yekaterinburg',
  },
  {
    value: 'Asia/Karachi',
    title: '(UTC+05:00) Islamabad, Karachi',
  },
  {
    value: 'Asia/Kolkata',
    title: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  },
  {
    value: 'Asia/Colombo',
    title: '(UTC+05:30) Sri Jayawardenepura',
  },
  {
    value: 'Asia/Kathmandu',
    title: '(UTC+05:45) Kathmandu',
  },
  {
    value: 'Antarctica/Almaty',
    title: '(UTC+06:00) Nur-Sultan (Astana)',
  },
  {
    value: 'Asia/Dhaka',
    title: '(UTC+06:00) Dhaka',
  },
  {
    value: 'Asia/Rangoon',
    title: '(UTC+06:30) Yangon (Rangoon)',
  },
  {
    value: 'Asia/Bangkok',
    title: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
  },
  {
    value: 'Asia/Novosibirsk',
    title: '(UTC+07:00) Novosibirsk',
  },
  {
    value: 'Asia/Hong_Kong',
    title: '(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi',
  },
  {
    value: 'Asia/Krasnoyarsk',
    title: '(UTC+08:00) Krasnoyarsk',
  },
  {
    value: 'Asia/Kuala_Lumpur',
    title: '(UTC+08:00) Kuala Lumpur, Singapore',
  },
  {
    value: 'Australia/Perth',
    title: '(UTC+08:00) Perth',
  },
  {
    value: 'Asia/Taipei',
    title: '(UTC+08:00) Taipei',
  },
  {
    value: 'Asia/Ulaanbaatar',
    title: '(UTC+08:00) Ulaanbaatar',
  },
  {
    value: 'Asia/Irkutsk',
    title: '(UTC+08:00) Irkutsk',
  },
  {
    value: 'Asia/Tokyo',
    title: '(UTC+09:00) Tokyo, Osaka, Sapporo',
  },
  {
    value: 'Asia/Seoul',
    title: '(UTC+09:00) Seoul',
  },
  {
    value: 'Asia/Yakutsk',
    title: '(UTC+09:00) Yakutsk',
  },
  {
    value: 'Australia/Adelaide',
    title: '(UTC+09:30) Adelaide',
  },
  {
    value: 'Australia/Darwin',
    title: '(UTC+09:30) Darwin',
  },
  {
    value: 'Australia/Brisbane',
    title: '(UTC+10:00) Brisbane',
  },
  {
    value: 'Australia/Melbourne',
    title: '(UTC+10:00) Canberra, Melbourne, Sydney',
  },
  {
    value: 'Asia/Vladivostok',
    title: '(UTC+10:00) Vladivostok',
  },
  {
    value: 'Pacific/Guam',
    title: '(UTC+10:00) Guam, Port Moresby',
  },
  {
    value: 'Australia/Hobart',
    title: '(UTC+10:00) Hobart',
  },
  {
    value: 'Pacific/Guadalcanal',
    title: '(UTC+11:00) Solomon Is., New Caledonia',
  },
  {
    value: 'Asia/Sakhalin',
    title: '(UTC+11:00) Sakhalin',
  },
  {
    value: 'Pacific/Auckland',
    title: '(UTC+12:00) Auckland, Wellington',
  },
  {
    value: 'Etc/GMT-12',
    title: '(UTC+12:00) Coordinated Universal Time+12',
  },
  {
    value: 'Pacific/Fiji',
    title: '(UTC+12:00) Fiji',
  },
  {
    value: 'Asia/Magadan',
    title: '(UTC+12:00) Magadan',
  },
  {
    value: 'Asia/Kamchatka',
    title: '(UTC+12:00) Petropavlovsk-Kamchatsky',
  },
  {
    value: 'Pacific/Tongatapu',
    title: '(UTC+13:00) Nuku`alofa',
  },
  {
    value: 'Pacific/Apia',
    title: '(UTC+13:00) Samoa',
  },
]
